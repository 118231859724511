const mapTraderProfileFieldsToReq = (values) => {
    const { displayName, username, location, } = values;
    return {
        displayName,
        username,
        location: (location === null || location === void 0 ? void 0 : location.length) ? location : undefined,
        type: 'TRADER',
    };
};
export default mapTraderProfileFieldsToReq;

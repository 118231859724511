const INTRODUCTION_INIT_VALUES = {
    displayName: '',
    location: '',
    username: '',
    initialUsername: '',
};
const ADD_PHOTO_INIT_VALUES = {
    initialImage: null,
};
const EDIT_PHOTO_INIT_VALUES = {
    initialImage: null,
    image: null,
};
const PERSONAL_DETAILS_INIT_VALUES = {
    firstName: '',
    lastName: '',
};
const ADDRESS_DETAILS_INIT_VALUES = {
    addressLine: '',
    additionalAddressLine: '',
    city: '',
    region: '',
    country: '',
    postalCode: '',
};
const IDENTITY_DETAILS_INIT_VALUES = {
    countryIdentityName: '',
    taxIdentityNumber: '',
    docType: 'ID_CARD',
    number: '',
};
const UPLOAD_DOCUMENTS_INIT_VALUES = {
    personPhoto: null,
    passportPhoto: null,
};
const SIDEBAR_INNER_PROPS = {
    0: { displayCancelBtn: true },
    1: { displayCancelBtn: true },
    2: { displayCancelBtn: true, showTitle: true },
    3: { displayCancelBtn: true, showTitle: true },
    5: { displayCancelBtn: true, showTitle: false },
    6: { displayCancelBtn: true, showTitle: true },
    7: { displayCancelBtn: true, showTitle: true },
    8: { displayCancelBtn: true, showTitle: true },
    9: { displayCancelBtn: true, showTitle: true },
    10: { displayCancelBtn: true, showTitle: true },
    11: { displayCancelBtn: false },
};
export { INTRODUCTION_INIT_VALUES, ADD_PHOTO_INIT_VALUES, EDIT_PHOTO_INIT_VALUES, PERSONAL_DETAILS_INIT_VALUES, ADDRESS_DETAILS_INIT_VALUES, IDENTITY_DETAILS_INIT_VALUES, UPLOAD_DOCUMENTS_INIT_VALUES, SIDEBAR_INNER_PROPS, };

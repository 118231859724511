import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useImperativeHandle, } from 'react';
import { useTranslation } from 'react-i18next';
import useTraderCreation from './api/useTraderCreation';
const StepWithRefComponent = ({ Element, initValues, }, stepRef) => {
    const { t } = useTranslation();
    const { handleClose, goToNextStep, goToPrevStep, handleSkip, returnToEdit, exitFromForm, isCreating, isPrefilled, } = useTraderCreation();
    useImperativeHandle(stepRef, () => ({
        handleClose,
        goToPrevStep,
    }), []);
    return (_jsx(Element, { initValues: initValues, goToNextStep: goToNextStep, goToPrevStep: goToPrevStep, exitFromForm: exitFromForm, returnToEdit: returnToEdit, isCreating: isCreating, isPrefilled: isPrefilled, skip: handleSkip }));
};
/**
 * implements inside logic which is serve to go to warning step
 */
const StepWithRefHOC = forwardRef(StepWithRefComponent);
export default StepWithRefHOC;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, useMemo, useRef, } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { useAppSelector } from '@app/store/Hooks';
import BackLinkButton from '@shared/ui/buttons/BackLinkButton';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import { Wizard } from '@shared/ui/misc/Wizard';
import AddPhoto from './steps/AddPhoto';
import Initial from './steps/Initial';
import Introduction from './steps/Introduction';
import EditPhoto from './steps/EditPhoto';
import AddPhotoConfirm from './steps/AddPhotoConfirm';
import GetVerification from './steps/GetVerification';
import PersonalDetails from './steps/PersonalDetails';
import AddressDetails from './steps/AddressDetails';
import IdentityDetails from './steps/IdentityDetails';
import UploadDocuments from './steps/UploadDocuments';
import FinalStep from './steps/FinalStep';
import Warning from './steps/Warning';
import { SIDEBAR_INNER_PROPS, } from './model';
import StepWithRefHOC from './StepWithRefHOC';
const CreateTraderStepsComponent = ({ initValues }, ref) => (_jsxs(Wizard, { children: [_jsx(StepWithRefHOC, { Element: Initial, initValues: initValues, ref: ref }), _jsx(StepWithRefHOC, { Element: Introduction, initValues: initValues, ref: ref }), _jsx(StepWithRefHOC, { Element: AddPhoto, ref: ref, initValues: initValues }), _jsx(StepWithRefHOC, { Element: EditPhoto, initValues: initValues, ref: ref }), _jsx(StepWithRefHOC, { Element: AddPhotoConfirm, ref: ref, initValues: initValues }), _jsx(StepWithRefHOC, { Element: GetVerification, ref: ref, initValues: initValues }), _jsx(StepWithRefHOC, { Element: PersonalDetails, ref: ref, initValues: initValues }), _jsx(StepWithRefHOC, { Element: AddressDetails, ref: ref, initValues: initValues }), _jsx(StepWithRefHOC, { Element: IdentityDetails, initValues: initValues, ref: ref }), _jsx(StepWithRefHOC, { Element: UploadDocuments, initValues: initValues, ref: ref }), _jsx(StepWithRefHOC, { Element: FinalStep, initValues: initValues, ref: ref }), _jsx(StepWithRefHOC, { Element: Warning, initValues: initValues, ref: ref })] }));
const CreateTraderSteps = forwardRef(CreateTraderStepsComponent);
const CreateTraderProfile = () => {
    const { t } = useTranslation();
    const { createTraderCurrentStep, createTraderProfileValues, } = useAppSelector(state => state.profile);
    const stepsRef = useRef(null);
    const theme = useTheme();
    const handleClose = () => {
        if (stepsRef === null || stepsRef === void 0 ? void 0 : stepsRef.current) {
            stepsRef.current.handleClose();
        }
    };
    const handleBacklinkClick = () => {
        if (stepsRef === null || stepsRef === void 0 ? void 0 : stepsRef.current) {
            stepsRef.current.goToPrevStep();
        }
    };
    const sidebarInnerProps = useMemo(() => {
        var _a;
        if (typeof createTraderCurrentStep === 'number') {
            return Object.assign(Object.assign({}, SIDEBAR_INNER_PROPS[createTraderCurrentStep]), { title: ((_a = SIDEBAR_INNER_PROPS[createTraderCurrentStep]) === null || _a === void 0 ? void 0 : _a.showTitle) ? (_jsx(BackLinkButton, { onClick: handleBacklinkClick, buttonPadding: '0px', children: t('profile.create_trader.steps.backlink_btn.text', { ns: 'common' }) })) : null });
        }
        return {};
    }, [createTraderCurrentStep]);
    return (_jsx(SidebarInner, Object.assign({}, sidebarInnerProps, { padding: `${theme.spacing_sizes.xs * 6.25}px ${theme.spacing_sizes.s * 5}px`, cancelBtnProps: { onClick: handleClose, iconPadding: 6 }, children: _jsx(CreateTraderSteps, { ref: stepsRef, initValues: createTraderProfileValues }) })));
};
export default CreateTraderProfile;
